<template>
    <div class="popDialog">
        <el-dialog
                v-if="editDialog"
                width="95%"
                top="30px"
                :visible.sync="editDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog">
            <template v-slot:title>
                <el-row>
                    <el-col :span="12" class="selectTitleMax">
                        <span>編輯會員車輛資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產品牌:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="brand" placeholder="請輸入資產品牌" style="width: 100%"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>資產類型<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="assetsModelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in assetsOptions"
                                            :key="item.assetsModelTypeId"
                                            :label="item.assetsModel"
                                            :value="item.assetsModelTypeId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>資產顏色<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="assetsColor" placeholder="請輸入資產顏色"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產識別碼<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="identifier" placeholder="請輸入資產識別碼"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>價格區間:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="priceRange" placeholder="請輸入價格區間"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>國內/國外:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isImported">
                                    <el-radio label="1">國外</el-radio>
                                    <el-radio label="0">國內</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產服務等級<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="serviceLevelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in serviceLevelOptions"
                                            :key="item.serviceLevelTypeId"
                                            :label="item.serviceLevel"
                                            :value="item.serviceLevelTypeId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格起算日:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="startDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間"
                                        @change="dateChangeFormat(startDate,'1')"
                                >
                                </el-date-picker>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格終止日:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="endDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間"
                                        @change="dateChangeFormat(endDate,'2')">
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span style="text-align: left;font-size: 16px;">會員資格狀態:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="status" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in statusOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格使用:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isUse">
                                    <el-radio label="0">不使用</el-radio>
                                    <el-radio label="1">使用</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格購買金額:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="purchaseAmount"
                                        placeholder="請輸入會員資格購買金額"
                                        type="number"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>折扣率:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="discountPercentage"
                                        placeholder="請輸入折扣率"
                                        type="number"></el-input>
                                <!--                                <el-input-number-->
                                <!--                                        v-model="discountPercentage"-->
                                <!--                                        :min="0"-->
                                <!--                                        :max="100"-->
                                <!--                                        :step="1"-->
                                <!--                                        placeholder="請輸入折扣率"-->
                                <!--                                >-->
                                <!--                                </el-input-number>-->
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isActive"
                                >
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="contactName" placeholder="請輸入聯絡人"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人性別:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="contactGender"
                                >
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人電話:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="contactPhoneNumber" placeholder="請輸入聯絡人電話"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="remark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>照片1<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="showImage"
                                     :src="'data:image/jpg;base64,' + photoBase64_1">
                                <input type="file" ref="fileInput1" @change="handleFileUpload1" accept=".jpg"/>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>照片2<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="showImage"
                                     :src="'data:image/jpg;base64,' + photoBase64_2">
                                <input type="file" ref="fileInput2" @change="handleFileUpload2" accept=".jpg"/>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>照片3<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="showImage"
                                     :src="'data:image/jpg;base64,' + photoBase64_3">
                                <input type="file" ref="fileInput3" @change="handleFileUpload3" accept=".jpg"/>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="editSave">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    // 导入Moment.js
    import moment from 'moment';

    export default {
        name: "editDialog",
        components: {},
        props: {
            editDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                row: null,
                token: null,
                customerAssetsId: null,
                companyId: null,
                customerId: null,
                brand: null,
                assetsModel: null,
                assetsColor: null,
                identifier: null,
                priceRange: null,
                isImported: null,
                serviceLevel: null,
                memberNumber: null,
                startDate: null,
                startDateString: null,
                endDate: null,
                endDateString: null,
                status: null,
                isUse: null,
                statusChangeDate: null,
                purchaseAmount: null,
                discountPercentage: null,
                photo: null,
                photoBase64String1: null,
                photoBase64String2: null,
                photoBase64String3: null,
                photoBase64_1: null,
                photoBase64_2: null,
                photoBase64_3: null,
                photoPath1: null,
                photoPath2: null,
                photoPath3: null,
                remark: null,
                isActive: null,
                showImage: true,
                serviceLevelOptions: [],
                assetsOptions: [],
                assetsModelTypeId: null,
                serviceLevelTypeId: null,
                statusOptions: [
                    {value: '正常', label: '正常'},
                    {value: '停權', label: '停權'},
                    {value: '過期', label: '過期'},
                    {value: '棄用', label: '棄用'},
                ],
                reload: false,
                contactName: null,
                contactGender: null,
                contactPhoneNumber: null,
                companyType: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        computed: {
            inputType() {
                return this.passwordVisible ? 'text' : 'password';
            }
        },
        mounted() {
        },
        methods: {
            init(row) {
                this.row = row;
                if (this.row != null && this.row != "") {
                    this.companyId = this.row.companyId;
                    this.customerAssetsId = this.row.customerAssetsId;
                    this.customerId = this.row.customerId;
                    this.brand = this.row.brand;
                    this.assetsModelTypeId = this.row.assetsModelTypeId;
                    this.assetsColor = this.row.assetsColor;
                    this.identifier = this.row.identifier;
                    this.priceRange = this.row.priceRange;
                    this.isImported = this.isImported == true ? "1" : "0";
                    this.serviceLevelTypeId = this.row.serviceLevelTypeId;
                    this.memberNumber = null;
                    this.startDate = this.row.startDate;
                    this.endDate = this.row.endDate;
                    this.status = this.row.status;
                    this.isUse = this.row.isUse == true ? "1" : "0";
                    this.statusChangeDate = this.row.statusChangeDate;
                    this.purchaseAmount = this.row.purchaseAmount;
                    this.discountPercentage = this.row.discountPercentage;
                    this.photo = this.row.photo;
                    this.photoBase64_1 = this.row.photoBase64_1;
                    this.photoBase64_2 = this.row.photoBase64_2;
                    this.photoBase64_3 = this.row.photoBase64_3;
                    this.photoPath1 = this.row.photoPath1;
                    this.photoPath2 = this.row.photoPath2;
                    this.photoPath3 = this.row.photoPath3;
                    this.isActive = this.row.isActive == true ? "1" : "0";
                    this.remark = this.row.remark;
                    this.contactName = this.row.contactName;
                    this.contactGender = this.row.contactGender == null ? null : this.row.contactGender == true ? "1" : "0";
                    this.contactPhoneNumber = this.row.contactPhoneNumber;
                    this.companyType = this.row.companyType;
                }
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'));
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'));
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId;
                        console.log("editDialog取得loginReturnDto.companyId=" + this.companyId);
                    }
                    //console.log("editCompanyDialog取得token=" + this.token);
                }
                this.searchAssetsModelTypeList();
                this.searchServiceLevelTypeList();
                this.clearData();
            },
            async editSave() {
                //編輯儲存
                let tmp = this;
                let url = "consumer/updated/assets";
                try {
                    if (this.statusChangeDate != null && this.statusChangeDate != '') {
                        var outputFormat = "YYYY-MM-DD HH:mm:ss";
                        // 创建一个新的 Date 对象
                        var date = new Date(this.statusChangeDate.toString());
                        // 检查日期是否有效
                        if (isNaN(date.getTime())) {
                            return null;
                        }
                        // 使用 moment.js 库来转换日期格式
                        this.statusChangeDateString = moment(date).format(outputFormat);
                        console.log("statusChangeDateString=" + this.statusChangeDateString);
                    }
                    let parameter = {
                        "customerAssetsId": this.customerAssetsId,
                        "customerId": this.customerId,
                        "brand": this.brand,
                        "assetsModelTypeId": this.assetsModelTypeId,
                        "assetsColor": this.assetsColor,
                        "identifier": this.identifier,
                        "priceRange": this.priceRange,
                        "isImported": this.isImported == null ? null : this.isImported == "0" ? false : true,
                        "serviceLevelTypeId": this.serviceLevelTypeId,
                        "startDate": null,
                        "startDateString": this.startDateString == null || this.startDateString == '' ? this.startDate : this.startDateString,
                        "endDate": null,
                        "endDateString": this.endDateString == null || this.endDateString == '' ? this.endDate : this.endDateString,
                        "status": this.status,
                        "isUse": this.isUse == null ? null : this.isUse == "0" ? false : true,
                        "statusChangeDate": null,
                        "statusChangeDateString": this.statusChangeDateString,
                        "purchaseAmount": this.purchaseAmount == null ? null : parseInt(this.purchaseAmount, 10),
                        "discountPercentage": this.discountPercentage == null ? 0 : parseInt(this.discountPercentage, 10),
                        "photo1": this.photoBase64String1 == null || this.photoBase64String1 == '' ? this.photo1 : null,
                        "photo2": this.photoBase64String2 == null || this.photoBase64String2 == '' ? this.photo2 : null,
                        "photo3": this.photoBase64String3 == null || this.photoBase64String3 == '' ? this.photo3 : null,
                        "photoBase64_1": this.photoBase64String1,
                        "photoBase64_2": this.photoBase64String2,
                        "photoBase64_3": this.photoBase64String3,
                        "photoPath1": this.photoPath1,
                        "photoPath2": this.photoPath2,
                        "photoPath3": this.photoPath3,
                        "remark": this.remark,
                        "isActive": this.isActive == null ? null : this.isActive == "0" ? false : true,
                        "contactName": this.contactName,
                        "contactGender": this.contactGender == null ? null : parseInt(this.contactGender, 10),
                        "contactPhoneNumber": this.contactPhoneNumber,
                    };
                    if (!this.checkEditParameter(parameter)) {
                        console.log("更新驗證資料失敗!");
                        return;
                    }
                    tmp.reload = true;
                    tmp.loadingView();
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                    if (responseData) {
                        tmp.$api.openMsg("編輯資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog();
                            // tmp.$emit('init');
                        }, 1000);
                    } else {
                        tmp.$api.openMsg("編輯資料失敗!", "error");
                        console.log("編輯資料失敗!");
                    }
                } catch (error) {
                    console.log("編輯資料發生異常! error=" + error);
                } finally {
                    tmp.closeLoading();
                }
                tmp.closeLoading();
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeDialog() {
                console.log("關閉Pop");
                this.showImage = true;
                this.clearData();
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
            },
            clearData() {
                console.log("清除資料");
                this.clearFileInput();
                this.photoBase64String1 = null;
                this.photoBase64String2 = null;
                this.photoBase64String3 = null;
                this.startDateString = null;
                this.endDateString = null;
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
            handleDateChange(date) {
                if (date) {
                    // 將 Date 對象轉換為字串，格式為 YYYY-MM-DD
                    this.formattedBirthday = date.toISOString().slice(0, 10);
                } else {
                    this.formattedBirthday = '';
                }
            },
            checkEditParameter(parameter) {
                var ststus = true;
                if (parameter != null && parameter != '') {
                    if (parameter.startDateString != null && parameter.startDateString != '') {
                        if (parameter.endDateString == null || parameter.endDateString == '') {
                            if (parameter.endDate == null || parameter.endDate == '') {
                                console.log("會員資格起算日不為空時會員資格終止日不可為空!");
                                this.$api.openMsg("會員資格起算日不為空時會員資格終止日不可為空!", "error");
                                return false;
                            }
                        }
                        const start = new Date(parameter.startDateString);
                        const end = new Date(parameter.endDateString);
                        console.log("parameter.startDateString=" + parameter.startDateString);
                        console.log("parameter.endDateString=" + parameter.endDateString);
                        if (start > end) {
                            console.log("會員資格起算日及會員資格終止日設定時間異常!");
                            this.$api.openMsg("會員資格起算日及會員資格終止日設定時間異常!", "error");
                            return false;
                        }
                    }
                    if (parameter.endDateString != null && parameter.endDateString != '') {
                        if (parameter.startDateString == null || parameter.startDateString == '') {
                            if (parameter.startDate == null || parameter.startDate == '') {
                                console.log("會員資格終止日不為空時會員資格起算日不可為空!");
                                this.$api.openMsg("會員資格終止日不為空時會員資格起算日不可為空!", "error");
                                return false;
                            }
                        }
                    }
                    if (parameter.customerAssetsId == null || parameter.customerAssetsId == '') {
                        console.log("會員資產資料表流水號不可為空!");
                        this.$api.openMsg("會員資產資料表流水號不可為空!", "error");
                        return false;
                    }
                    if (parameter.customerId == null || parameter.customerId == '') {
                        console.log("會員帳號資料ID不可為空!");
                        this.$api.openMsg("會員帳號資料ID不可為空!", "error");
                        return false;
                    }
                    if (parameter.assetsModelTypeId == null || !parameter.assetsModelTypeId) {
                        console.log("資產類型不可為空!");
                        this.$api.openMsg("資產類型不可為空!", "error");
                        return false;
                    }
                    if (parameter.identifier == null || !parameter.identifier) {
                        console.log("資產識別碼不可為空!");
                        this.$api.openMsg("資產識別碼不可為空!", "error");
                        return false;
                    }
                    if (parameter.serviceLevelTypeId == null || parameter.serviceLevelTypeId == '') {
                        console.log("資產服務等級不可為空!");
                        this.$api.openMsg("資產服務等級不可為空!", "error");
                        return false;
                    }
                    if (parameter.status == null || parameter.status == '') {
                        console.log("會員資格狀態不可為空!");
                        this.$api.openMsg("會員資格狀態不可為空!", "error");
                        return false;
                    }
                    if (parameter.isActive == null || parameter.isActive == '') {
                        console.log("是否啟用不可為空!");
                        this.$api.openMsg("是否啟用不可為空!", "error");
                        return false;
                    }
                } else {
                    console.log("必填資料不可為空!");
                    this.$api.openMsg("必填資料不可為空!", "error");
                    return false;
                }
                return ststus;
            },
            dateChangeFormat(dateStr, no) {
                try {
                    if (dateStr == null || dateStr == '') {
                        return;
                    }
                    var outputFormat = "YYYY-MM-DD HH:mm:ss";
                    // 创建一个新的 Date 对象
                    var date = new Date(dateStr.toString());
                    // 检查日期是否有效
                    if (isNaN(date.getTime())) {
                        return null;
                    }
                    // 使用 moment.js 库来转换日期格式
                    var formattedDate = moment(date).format(outputFormat);
                    console.log("formattedDate=" + formattedDate);
                    if (no == '1') {
                        this.startDateString = formattedDate;
                    } else {
                        this.endDateString = formattedDate;
                    }
                } catch (e) {
                    console.log("日期選擇器發生異常! error=" + e);
                }
            },
            async handleFileUpload1(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        if (this.$refs.fileInput1 != null) {
                            this.$refs.fileInput1.value = '';
                        }
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64String1 = await this.readFileAsBase64(file);
                    //隱藏圖片
                    tmp.showImage = false;
                    console.log("匯入客戶資料! photoBase64String1=" + this.photoBase64String1);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            async handleFileUpload2(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        if (this.$refs.fileInput2 != null) {
                            this.$refs.fileInput2.value = '';
                        }
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64String2 = await this.readFileAsBase64(file);
                    //隱藏圖片
                    tmp.showImage = false;
                    console.log("匯入客戶資料! photoBase64String2=" + this.photoBase64String2);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            async handleFileUpload3(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        if (this.$refs.fileInput3 != null) {
                            this.$refs.fileInput3.value = '';
                        }
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    // this.byteArray = await this.readFileAsBytes(file);
                    this.photoBase64String3 = await this.readFileAsBase64(file);
                    //隱藏圖片
                    tmp.showImage = false;
                    console.log("匯入客戶資料! photoBase64String3=" + this.photoBase64String3);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            readFileAsBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result.split(',')[1]); // 去掉前缀 'data:image/jpeg;base64,'
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file); // 读取文件为 DataURL
                });
            },
            // 清空文件上传 input 元素的值
            clearFileInput() {
                if (this.$refs.fileInput1 != null) {
                    this.$refs.fileInput1.value = '';
                }
                if (this.$refs.fileInput2 != null) {
                    this.$refs.fileInput2.value = '';
                }
                if (this.$refs.fileInput3 != null) {
                    this.$refs.fileInput3.value = '';
                }
            },
            async searchAssetsModelTypeList() {
                //依據條件查詢全部資產類型資料
                let tmp = this;
                let url = "company/search/assetsModel";
                let parameter = {
                    "companyId": this.companyId,
                    "assetsModel": this.assetsModel,
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.assetsOptions = responseData;
                    console.log("取得下拉選單資料 assetsOptions=" + tmp.assetsOptions);
                }
            },
            async searchServiceLevelTypeList() {
                //依據條件查詢全部資產服務等級資料
                let tmp = this;
                let url = "company/search/serviceLevel";
                let parameter = {
                    "companyId": this.companyId,
                    "serviceLevel": this.serviceLevel,
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.serviceLevelOptions = responseData;
                    console.log("取得下拉選單資料 serviceLevelOptions=" + tmp.serviceLevelOptions);
                }
            },
        }
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }
</style>
